@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url(https://db.onlinewebfonts.com/c/774a72961dd69f90a772237432b1623e?family=Averta-Semibold);



:root {
  --color-secondary: #818286;
  --color-primary: #e280c6;
  --color-primary2: #8c72d7;
  --color-white: #fff;
  --color-black: #0e0e0e;
  --background-gradient: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 40%, rgba(226, 128, 198, 1) 55%);
  --background-gradient2: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 20%, rgba(226, 128, 198, 1) 100%);
}


/***** Start Scrollbar CSS *****/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: var(--background-gradient); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--background-gradient2); 
}
/***** End Scrollbar CSS *****/
/***** Cart Body Details *****/
.cart_body {
  position: relative;
  width: 24rem;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.cart_body p {
  font-size: 22px;
}

.emptycart_img {
  width: 5rem;
  font-size: 40px;
}

.cart_body_details {
  width: 26rem;
  padding: 10px;
}

.cart_body_details_img {
  width: 5rem;
  height: 5rem;
}

.cart_body_details_dlt {
  color: red !important;
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
}

/***** Cart Body Details *****/
/***** Start Cart Page css *****/
.main_cart {
  padding: 50px 0px;
}

.total__tab_section {
  border: 1px solid #958f8f;
  border-radius: 15px;
  padding: 16px;
}

.totla__inner {
  display: flex;
  justify-content: space-between;
}

.inner__cart tbody td {
  padding: 10px;
  vertical-align: middle;
}

.cart-qnty {
  width: 50%;
  cursor: pointer;
  background: rgb(221, 221, 221);
  color: var(--color-secondary);
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  align-items: center;
}

.cart-qnty-span {
  font-size: 22px;
  background: var(--color-primary);
  color: var(--color-white);
  transition: all 0.3s ease-in-out;
}

.cart-qnty-span:hover {
  background: var(--color-primary2);
}

.cart-qnty span {
  padding: 5px 20px;
}
.cart-qnty button {
  padding: 5px 20px;
}

.cart_product {
  align-items: center;
  display: flex;
}

.cart_product img {
  background-color: #e9f6fa;
  max-width: 100px;
  padding: 10px;
  width: 100%;
}

.chect_out_btn {
  background: var(--color-primary);
  border: none;
  border-radius: 60px;
  color: var(--color-white);
  display: inline-block;
  font-size: 18px;
  padding: 15px 25px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.chect_out_btn:hover {
  background: var(--color-primary2);
  color: var(--color-white);
}

/***** End Cart Page css *****/
/***** Start Check Out Page css *****/
.check_out_table_2 {
  background: #f5f5f5;
  border-radius: 25px;
  padding: 40px;
}

.check_out_table_2 h4 {
  padding: 10px;
  font-size: 25px;
  font-weight: 700;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1020;
  background: transparent;
}

.check_out_table_2 .content ul li {
  display: block;
  /* padding: 0px 30px; */
  font-size: 15px;
  font-weight: 400;
  color: #333;
  margin-bottom: 12px;
}

.check_out_table_2 .content ul li span {
  display: inline-block;
  float: right;
}

.check_out_table_2 .content ul li.last {
  padding-top: 12px;
  border-top: 1px solid #ebebeb;
  display: block;
  font-size: 15px;
  font-weight: 400;
  color: #333;
}

.bottom__total .proceed_to_checkout_btn {
  width: 100%;
}

.proceed_to_checkout_btn {
  background: var(--color-primary);
  border: none;
  border-radius: 60px;
  color: var(--color-white);
  display: inline-block;
  font-size: 18px;
  padding: 15px 25px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.proceed_to_checkout_btn:hover {
  background: var(--color-primary2);
  color: var(--color-white);
}

.check_out_inputs {
  margin: 50px 0px;
}

.check_out_input {
  margin: 20px 0px;
}

.check_out_input .form-label {
  font-size: 20px;
  font-weight: 500;
}

.check_out_inputs input {
  border-radius: 50px;
  padding: 10px;
}

.bottom_total .proceed_to_checkout_btn {
  margin-top: 12px;
  width: 100%;
}

.bottom_total .proceed_to_checkout_btn a {
  text-decoration: none;
  color: var(--color-white);
}

/***** End Check Out Page css *****/
/***** Start Invoice Page css *****/
.receipt-content {
  background: #ECEEF4;
  justify-content: center;
}

.receipt-content .invoice-wrapper {
  background: #FFF;
  border: 1px solid #CDD3E2;
  box-shadow: 1px 1px 10px -5px #878787;
  padding: 40px 40px 60px;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 4px;
}

.receipt-content .invoice-wrapper .payment-info {
  margin-top: 25px;
  padding-top: 15px;
}

b.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.text-right-print {
  text-align: right !important;
}

.receipt-content .invoice-wrapper .payment-details {
  border-top: 2px solid #EBECEE;
  margin-top: 30px;
  padding-top: 20px;
  line-height: 22px;
}

/***** End Invoice Page css *****/
/***** Start Order Tracking Page css *****/

.order_tracking {
  width: 100%;
  display: flex;
  justify-content: center;
}

.order_tracking_inputs {
  padding: 80px 0px;
  width: 40% !important;
}

ol.progtrckr {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 5em;
}

ol.progtrckr[data-progtrckr-steps="2"] li {
  width: 49%;
}

ol.progtrckr[data-progtrckr-steps="3"] li {
  width: 33%;
}

ol.progtrckr[data-progtrckr-steps="4"] li {
  width: 24%;
}

ol.progtrckr[data-progtrckr-steps="5"] li {
  width: 33%;
}

ol.progtrckr[data-progtrckr-steps="6"] li {
  width: 16%;
}

ol.progtrckr[data-progtrckr-steps="7"] li {
  width: 14%;
}

ol.progtrckr[data-progtrckr-steps="8"] li {
  width: 12%;
}

ol.progtrckr[data-progtrckr-steps="9"] li {
  width: 11%;
}

ol.progtrckr li.progtrckr-done {
  color: var(--color-primary2);
  border-bottom: 10px solid var(--color-primary2);
  font-size: 12px;
}

ol.progtrckr li.progtrckr-done svg {
  font-size: 28px;
  margin-right: 8px;
}

ol.progtrckr li.progtrckr-todo {
  font-size: 12px;
  color: silver;
  border-bottom: 10px solid silver;
}

ol.progtrckr li.progtrckr-todo svg {
  font-size: 28px;
  margin-right: 8px;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -4.23em;
  float: left;
  left: 50%;
  line-height: 1em;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: var(--color-primary2);
  height: 2.2em;
  width: 2.2em;
  line-height: 2.2em;
  border: none;
  border-radius: 2.2em;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  font-size: 2.2em;
  bottom: -1.95em;
}

.order_id{
  padding: 0px;
}

/***** End Order Tracking Page css *****/