@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url(https://db.onlinewebfonts.com/c/774a72961dd69f90a772237432b1623e?family=Averta-Semibold);

@font-face {
  font-family: Averta !important;
  src: url(./fonts/Averta-Semibold.ttf);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  font-family: "Titillium Web", sans-serif !important;
  line-height: 1.7;
  overflow-x: hidden;
}

:root {
  --color-secondary: #818286;
  --color-primary: #e280c6;
  --color-primary2: #8c72d7;
  --color-white: #fff;
  --color-black: #0e0e0e;
  --background-gradient: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 40%, rgba(226, 128, 198, 1) 55%);
  --background-gradient2: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 20%, rgba(226, 128, 198, 1) 100%);
}

a {
  text-decoration: none !important;
}

.line hr {
  margin-top: 80px;
  border: none;
  height: 1px;
  background: linear-gradient(-90deg, rgba(75, 75, 75, 1) 36%, rgba(226, 128, 198, 0) 100%);
}

.line2 hr {
  margin-top: 80px;
  border: none;
  height: 1px;
  background: linear-gradient(-90deg, rgba(226, 128, 198, 0) 0%, rgba(75, 75, 75, 1) 50%, rgba(226, 128, 198, 0) 100%);
}


/* Bottom to top button style */
.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.icon-style {
  background-color: #e280c6;
  border: 1px solid #fff;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #e280c6;
  border: 1px solid #e280c6;
}
/******* Bottom to top button style *******/
.nav_bg {
  background: #020001;
  padding-top: 15px;
}

.me_auto {
  margin-left: auto;
  gap: 25px;
}


.nav_bar_a {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
}

.nav_bar_a a {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  text-decoration: none;
  padding: 20px 0px;
}

.nav_bar_a a::before {
  position: absolute;
  content: "";
  background: var(--background-gradient);
  width: 0%;
  height: 3px;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.6s ease;
}

.nav_bar_a a:hover:before {
  color: var(--color-white);
  width: 100%;
}

nav.nav_bar.navbar.navbar-expand-lg.navbar-light {
  padding: 0px;
}

a.nav_dropdown.dropdown-item {
  padding: 10px;
}

a.nav_link.active::before {
  position: absolute;
  content: "";
  background: var(--background-gradient);
  width: 100%;
  height: 3px;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.6s ease;
}

.logo_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 45px;
}

.logo_section_contact a.link {
  font-weight: 600;
  text-decoration: none;
  background: var(--background-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo_section_contact p {
  color: var(--color-white);
  margin: 0px;
}

.searchbar {
  width: 450px;
}

.searchbar input {
  border-radius: 15px 0px;
  outline: none;
}

.searchbar button {
  padding: 0px 10px;
  border-radius: 0px;
  outline: none;
  border: 0px;
  color: var(--color-white);
  font-size: 22px;
  background: linear-gradient(0deg, rgba(140, 114, 215, 1) 0%, rgba(226, 128, 198, 1) 100%);
}

.header_input_icon svg {
  color: var(--color-white);
  font-size: 22px;
}

.header_input {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.header_input i::before {
  position: absolute;
  content: "";
  left: -4px;
  top: -20px;
  width: 0%;
  height: 0%;
  transition: 0.50s ease;
  background: linear-gradient(0deg, rgba(138, 114, 214, 0) 0%, rgba(138, 114, 214, 0.5234068627450981) 40%, rgba(226, 128, 198, 1) 100%);
  z-index: -1;
}

.header_input i:hover:before {
  width: 100%;
  padding: 40px 16px;
  height: 100%;
  transition: 0.50s ease;
}

.header_input i {
  position: relative;
  z-index: 99999;
}

a.nav_link {
  color: var(--color-white);
}

a#basic-nav-dropdown {
  color: var(--color-white);
}

.section_head h2 {
  padding: 60px 0px;
  font-size: 48px;
  font-weight: 700;
  background: var(--background-gradient);
  /* background-clip: text; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section_head {
  text-align: center;
}

.vertical-line {
  border-left: 2px solid var(--color-secondary);
  height: 70px;
}

/******** Our Products ********/
.nav .nav-link {
  transition: none;
  background: none;
}

.background-img {
  background-image: url(./images/bg_imgae.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.best_sell_icon img {
  width: 50px;
}

.best_sell_icon p {
  font-size: 18px;
  color: var(--color-secondary);
}

.best_sell_icon {
  text-align: center;
}

.best_sell {
  padding-bottom: 100px;
}

.best_sell_icons {
  display: flex;
  justify-content: space-around;
}

.card {
  border: none;
  transition: all 0.5s;
  margin-top: 25px;
}

.card:hover {
  box-shadow: 1px 1px 10px #878787;
}

img.card_img {
  border-radius: 200px;
  width: 250px;
  height: 250px;
  object-fit: cover;
  margin: 15px auto;
}

.star {
  color: gold;
}

.star span {
  color: var(--color-secondary);
}


.card_footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.card_price {
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.discount_price {
  font-size: 12px;
  color: var(--color-secondary);
}

button.card_btn2 {
  padding: 9px 18px;
  color: var(--color-white);
  background: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

button.card_btn2:hover {
  color: var(--color-white);
  background: transparent;
  border: 1px solid var(--color-secondary);
}

.cards {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  min-width: 110px;
  border-radius: 70px !important;
  border: 0px !important;
  background: linear-gradient(0deg, rgba(216, 201, 231, 1) 0%, rgba(241, 212, 233, 1) 100%);
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

ul.mb-3.nav.nav-tabs {
  display: flex;
  justify-content: space-around !important;
  border-bottom: 0px;
}

.products_btn {
  margin-top: 60px;
  text-align: center;
}

/******** Our Products ********/
/******** HeroSlider ********/
.slider_w {
  margin: 0px auto;
  width: 70%;
}

.hero-slider_img_bg {
  float: left;
}

.hero_img0 {
  width: 100%;
}

.hero_img {
  position: relative;
}

.hero_img1 {
  position: absolute;
  width: 720px;
  bottom: 114px;
  right: 354px;
  z-index: 999;
  transition: all 0.7s;
  animation-name: hero_img1;
  animation-duration: 4s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

/* .hero_img1 {
  position: absolute;
  width: 640px;
  bottom: 15px;
  right: 50%;
  z-index: 999;
  transition: all 0.7s;
  animation-name: hero_img1;
  animation-duration: 4s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  transform: translate(50%, -20%) !important;
  text-align: center;
} */
/* .hero_img1 {
  position: absolute;
  width: 720px;
  bottom: 114px;
  right: 354px;
  z-index: 999;
  transition: all 0.7s;
  animation-name: hero_img1;
  animation-duration: 4s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  left: 50%;
  transform: translate(-50%) !important;
} */

@keyframes hero_img1 {
  0% {
    transform: scale(1.0);
  }

  25% {
    transform: scale(1.02);
  }

  50% {
    transform: scale(1.0);
  }

  75% {
    transform: scale(1.02);
  }
}

.hero_img2 {
  overflow: hidden;
  position: absolute;
  width: 700px;
  bottom: 0px;
  right: 380px;
  z-index: 999;
  animation-name: hero_img2;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

/* .hero_img2 {
  overflow: hidden;
  position: absolute;
  width: 700px;
  bottom: 0px;
  z-index: 999;
  animation-name: hero_img2;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  left: 50%;
  transform: translate(-50%) !important;
} */

.hero_img_text {
  position: absolute;
  top: 216px;
  right: 0px;
  width: 100%;
  transition: all 0.7s;
  animation-name: hero_img_text;
  animation-duration: 4s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

@keyframes hero_img_text {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.15;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.15;
  }
}


ul.slick-dots {
  bottom: 15px !important;
  left: 480px !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
}

li.slick-active {
  background-image: url(./images/Group-40.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: transparent !important;
}

/******** HeroSlider ********/
/******** Our Brands ********/
.slider_imge img {
  text-align: center;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

.slick-prev:before {
  font-size: 28px !important;
  color: var(--color-secondary) !important;
}

.slick-next:before {
  font-size: 28px !important;
  color: var(--color-secondary) !important;
}

/******** Our Brands ********/
/******** Our Best Sell ********/
.best_sell .slick-slide {
  padding: 0 10px 0;
}

.slick-next:before {
  content: '→';
}

.best_sell ul.slick-dots {
  bottom: -30px !important;
  left: 0px !important;
}

/******** Our Best Sell ********/
/******** footer ********/
.footer_section {
  padding: 22px 0px;
  background: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 0%, rgba(226, 128, 198, 1) 100%);
}

.news h1 {
  font-size: 55px;
  font-weight: 700;
  color: var(--color-white);
}

.footer_inputs input {
  border-radius: 0px;
  outline: none;
}

.footer_section2 {
  background-color: #020001;
  padding: 32px 0px;
}

.footer_logo_section {
  color: var(--color-white);
}

.footer_logo_section p {
  margin-top: 30px;
}

.footer_logo_section h2 {
  font-family: system-ui;
  font-size: 30px;
  font-weight: 800;
  background: var(--background-gradient);
  /* background-clip: text; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 20px 0px;
}

.footer_icon {
  display: flex;
}

.footer_icon ul {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 20px;
}

.footer_icon ul li a {
  text-decoration: none;
  color: white;
}

.footer_icon ul li {
  border-radius: 52px;
  text-align: center;
  width: 45px;
  height: 45px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.footer_icon ul li:hover {
  border: 1.5px solid var(--color-white);
}

.footer_icon svg {
  font-size: 20px;
  border-radius: 36px;
}

.footer-links h5 {
  font-weight: bold;
  color: var(--color-white);
  font-size: 28px;
  padding: 0px 0px 20px 0px;
}

.footer-links ul li a {
  color: var(--color-white);
  font-weight: 300;
  padding: 0px 0px 15px 0px;
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.footer-links ul li a::before {
  position: absolute;
  content: "";
  background: var(--color-white);
  width: 0%;
  height: 2px;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.6s ease;
}

.footer-links ul li a:hover:before {
  color: var(--color-white);
  width: 100%;
}

.footer_section3 {
  padding: 10px 0px;
  background: var(--color-secondary);
}

.footer_card img {
  width: 350px;
}

.footer_card {
  text-align: right;
}


.footer-links ul {
  padding: 0px;
}

p.footer_section3_p {
  color: var(--color-white);
  margin: 0px;
}

/******** footer ********/
/******** Section Header ********/
.header {
  /* margin-top: 5rem; */
  height: 20rem;
  overflow: hidden;
  border-bottom: 2px solid var(--color-gray-400);
}

.header__container {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  place-items: center;
}

.header__container-bg {
  position: absolute;
  width: 100%;
}

.header__container-bg img {
  width: 100%;
}

.header__content {
  position: relative;
  width: 44%;
  margin: 0 auto;
  text-align: center;
  color: var(--color-white);
}

.header__content h1 {
  margin-bottom: 0.7rem;
  font-weight: 700;
  font-size: 55px;
}

.header_link a {
  color: rgba(255, 255, 255, 0.75);
  margin: 10px;
}

a.header_link_a {
  color: var(--color-primary);
}

/******** Section Header ********/
/******** About Us ********/
.about-bg {
  background-image: url(./images/about-bg.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.section_head_two h2 {
  padding: 60px 0px;
  font-size: 48px;
  font-weight: 700;
  background: var(--background-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section_head_two_p {
  text-align: justify;
}

.about_img {
  width: 90%;
  height: 100%;
  position: relative;
}

.about_img img {
  object-fit: cover;
  width: 100%;
  position: relative;
}

.padding {
  padding: 100px 0px;
}

.about_mission {
  position: absolute;
  background: var(--background-gradient2);
  width: 200px;
  color: var(--color-white);
  padding: 20px;
  bottom: -30px;
  left: 40px;
}

.about_img:before {
  content: "";
  width: 200px;
  height: 270px;
  position: absolute;
  display: block;
  background: #e5e5e5;
  top: -40px;
  right: -42px;

}

.about_mission h4 {
  color: var(--color-white);
  font-weight: 600;
}

.about_mission p {
  margin: 0px;
  font-size: 12px;
}

/******** About Us ********/
/******** Shop page ********/
.shop-bg {
  background-image: url(./images/shop-bg.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.category {
  margin-top: 27px;
}

.category_btn {
  outline: none;
  background: transparent;

}


h5.categories {
  background: var(--color-secondary);
  padding: 15px;
  margin: 0px;
  color: var(--color-white);
  font-size: 22px;
  font-weight: 600;
}

.category-name-color button.accordion-button.collapsed {
  /* font-size: 18px; */
  background: var(--background-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .category-name-color button.accordion-button::after {
  background-image: var(--background-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.shop_category .accordion-button::after {
  background-image: url(./images/arrow-img-removebg-preview.png) !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
}

.shop_category .accordion-button:not(.collapsed)::after {
  filter: brightness(3);
}

h5.price-range {
  background: var(--color-secondary);
  padding: 15px;
  margin: 0px;
  color: var(--color-white);
  font-size: 22px;
  font-weight: 600;
}

ul.price__range {
  margin: 0px;
  padding: 0px;
  background: var(--color-white);
  color: #000;
}

ul.price__range li {
  padding: 15px 20px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  gap: 40px;
}

.price-span {
  display: flex;
  gap: 20px;
  font-size: 13px;
}

.sort-by {
  display: flex;
  justify-content: space-between;
  margin-top: 27px;
  margin-left: 17px;
  align-items: center;
}

.sort-by-form-group {
  display: flex;
  gap: 20px;
}

form.sort-by-form select#rating-filter {
  width: 300px;
  padding: 13px 15px;
  border: 1px solid #dee2e6;
  color: var(--color-secondary);
}

form.sort-by-form-2 select {
  width: 150px;
  padding: 13px 15px;
  border: 1px solid #dee2e6;
  color: var(--color-secondary);
}

.page-show-span {
  display: flex;
  gap: 12px;
  font-size: 14px;
}

/******** Shop Page Css********/
/******** Testimonial Page Css ********/
.testimonial-bg {
  background-image: url(./images/shop-bg.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial-cards {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  padding: 90px 0px;
}

.testimonial_card.card {
  border-radius: 0px;
  border: transparent;
  margin: 0px;
}

.testimonial_card {
  width: 18rem;
  text-align: center;
}

img.testimonial_card_img {
  border-radius: 100px;
  width: 60px;
  height: 60px;
  object-fit: cover;
  text-align: center;
  margin: 0px auto;
  margin-top: 25px;
}

.testimonial_card_text {
  font-size: 12px;
  margin: 25px 0px;
}

h5.testimonial_card_name {
  font-size: 24px;
  font-weight: 600;
}

/******** Testimonial Page Css ********/
/******** Blogs Page Css ********/
.blog-bg {
  background-image: url(./images/shop-bg.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-post {
  display: flex;
  justify-content: center;
}

.blog-posts {
  padding: 90px 0px;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
  gap: 25px;
}

.blog_card {
  margin: 0px;
  width: 22rem;
  overflow: hidden;
  text-align: center;
  border: 0px !important;
  border-radius: 0px !important;
  background-color: transparent !important;
}

.blog_card:hover {
  box-shadow: none;
}

.date_admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog_text {
  align-items: center;
  background: #fff;
  width: 280px;
  margin: -60px auto 14px;
}

.blog_text:hover {
  box-shadow: 0px 0px 12px #878787;
  transition: all 0.3s ease-in-out;
}

.date_admin p {
  font-size: 12px;
}

p.bolg_text {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  text-align: justify;
}

.all_card {
  width: 18rem;
}

.all_card a {
  text-align: center;
}

img.blog_card_img {
  height: 200px;
  object-fit: cover;
  border-radius: 0px;
}

.date_admin a p {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--color-secondary);
}

.date_admin a p span svg {
  color: var(--color-primary);
}

/******** Blogs Page Css ********/
/******** Faqs Page Css ********/
.faq-bg {
  background-image: url(./images/shop-bg.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.faq_section {
  padding: 90px 0px;
}

.faq-accordion {
  width: 85%;
  margin: 0px auto;
}

.faq_section_heading {
  padding: 60px 0px;
  font-size: 48px;
  font-weight: 700;
  color: var(--color-gray-400);
  text-align: center;
}

.faq_section_heading span {
  color: var(--color-primary);
}

.faq-accordion button.accordion-button.collapsed {
  background: var(--color-black) !important;
  color: var(--color-white);
}

.faq-accordion button.accordion-button::after {
  color: var(--color-white);
}

.faq-accordion-body.accordion-body {
  font-size: 14px;
  background: #000;
  color: var(--color-secondary);
  border-left: 3px solid var(--color-primary);
}

.faq-accordion-header button.accordion-button {
  background: var(--background-gradient2) !important;
  color: #fff;
}

.faq-Accordion-item.accordion-item {
  margin-bottom: 30px;
}

h2.faq-accordion-header.accordion-header {
  border-left: 3px solid var(--color-primary);
}

.faq-accordion .faq-accordion-header .accordion-button::after {
  background-image: url(./images/arrow-img-removebg-preview.png) !important;
}

.faq-accordion .faq-accordion-header .accordion-button:not(.collapsed)::after {
  filter: brightness(3);
}

/******** Faqs Page Css ********/
/******** Start Single Product Page Css ********/

.product-bg {
  background-image: url(./images/shop-bg.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;

}

.product-detail {
  padding: 90px 0px;
  width: 90%;
  margin: 0px auto;
}

.product-detail-slider {
  width: 80% !important;
}

.product-detail-slider img {
  width: 100%;
}

.product-detail-slider-sec {
  width: 40% !important;
}

.product-detail-slider-sec img {
  width: 100%;
}

.product-detail .slick-slide {
  text-align: center;
}

.product_detail_info_Heading {
  display: flex;
  justify-content: space-between;
}

.product_detail_info_Heading h3 {
  font-weight: 700;
}

.product_detail_info_Heading h2 {
  font-weight: 700;
  background: var(--background-gradient2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p.product_detail_info_des {
  font-size: 14px;
  color: var(--color-secondary);
}

.product_detail_info_quantity {
  display: flex;
  gap: 50px;
  align-items: flex-start;
}

.product_detail_info_quantity p {
  font-size: 18px;
  font-weight: 600;
}

.quantity_btns button {
  background: transparent;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  line-height: 1px;
}

.quantity_btns button:hover {
  background: var(--background-gradient2);
  color: #fff;

}

.quantity_btns input {
  width: 100px;
  background: #fff;
  border: 1px solid #c0c0c0;
  text-align: center;
}

.quantity_btns {
  display: flex;
  gap: 14px;
  align-items: center;
}

.product_detail_info_delivery a {
  color: var(--color-secondary);
}

.product_detail_info_delivery a span svg {
  color: var(--color-black);
  transform: rotateY(180deg);
  font-size: 20px;
}

p.product_detail_info_delivery_link {
  display: flex;
  gap: 20px;
  font-size: 14px;
}

.product_detail_info_des_tab.nav.nav-pills {
  gap: 50px;
  padding: 60px 0px;
}

.main_btn2 .nav-link {
  padding: 0px;
}

.nav-pills .main_btn2 .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--color-white);
  border: 1px solid transparent;
  background: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 0%, rgba(226, 128, 198, 1) 100%);
  border-image: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 0%, rgba(226, 128, 198, 1) 100%);
  border-image-slice: 1;
  padding: 8px 25px;
  transition: all 0.5s;
}

.nav-pills .main_btn2 .nav-link {
  color: var(--color-secondary);
  background: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 0.25) 0%, rgba(226, 128, 198, 0.25) 100%);
  padding: 8px 25px;
  border-radius: 0px;
  font-weight: 600;
}
/******** Review Page Css ********/
.review_main h3 {
  font-weight: 600;
}

.single_product_review .product_rating {
  display: flex;
  flex-direction: column;
}
span.red_star {
  color: #ff2c18;
  display: inline-block;
}
.single_product_review .product_rating label {
  font-size: 18px;
  color: var(--color-black);
  margin: 8px 0px;
}

.product_rating svg {
  width: 25px;
}

.single_product_review .product_rating textarea {
  width: 100%;
  height: 200px;
  border: 1px solid #ddd;
  resize: none;
  border-radius: 0;
  color: #333;
  padding: 10px 15px;
}





/******** End Single Product Page Css ********/
/******** Page Not Found Page Css ********/
.page-not-found {
  padding: 9rem 0;
  text-align: center;
}

.page-not-found h2 {
  font-size: 10rem;
  font-weight: 700;
  background: var(--background-gradient2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-not-found h3 {
  font-size: 4.2rem;
  font-weight: 600;
  color: var(--color-black);
}

.page-not-found p {
  color: var(--color-black);
  margin: 2rem 0;
}
/******** Page Not Found Page Css ********/
/* custom_btn Button */
.custom_btn {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0px 25px 15px;
  min-width: 150px;
}

.custom_btn a {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px 25px;
  transition: 0.3s;
}

.custom_btn::after {
  position: absolute;
  content: "";
}

.custom_btn::before {
  position: absolute;
  content: "";
  background: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 0%, rgba(226, 128, 198, 1) 100%);
  transition: 0.3s ease-out;
}

.custom_btn a {
  color: var(--color-white);
  border: 1px solid transparent;
  border-image: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 0%, rgba(226, 128, 198, 1) 100%);
  border-image-slice: 1;
  transition: 0.2s 0.1s;
}

.custom_btn a:hover {
  color: var(--color-secondary);
  transition: 0.2s 0.1s;
}

.custom_btn::before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.custom_btn:hover::before {
  height: 0%;
}

/* custom_btn Button */
/* custom_btn_card Button */
.custom_btn_card {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.custom_btn_card a {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 22px;
  transition: 0.3s;
}

.custom_btn_card::after {
  position: absolute;
  content: "";
}

.custom_btn_card::before {
  position: absolute;
  content: "";
  background: var(--color-secondary);
  transition: 0.3s ease-out;
}

.custom_btn_card a {
  color: var(--color-white);
  border: 1px solid var(--color-secondary);
  transition: 0.2s 0.1s;
}

.custom_btn_card a:hover {
  color: var(--color-secondary);
  transition: 0.2s 0.1s;
}

.custom_btn_card::before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.custom_btn_card:hover::before {
  height: 0%;
}
/* custom_btn_card Button */
/* Loader css */
.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 3px solid var(--color-primary);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader2 {
  border: 3px solid var(--color-primary2);
  width: 220px;
  height: 220px;
  position: relative;
  top: -210px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader3 {
  border: 3px solid var(--color-primary);
  width: 240px;
  height: 240px;
  position: relative;
  top: -441px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader4 {
  border: 3px solid var(--color-primary2);
  width: 260px;
  height: 260px;
  position: relative;
  top: -692px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

@keyframes rotate{
  0%{transform:rotateZ(-360deg)}
  100%{transform:rotateZ(0deg)}
}
@keyframes rotate2{
0%{transform:rotateZ(360deg)}
100%{transform:rotateZ(0deg)}
}
#text {
  color: rgb(14, 13, 13);
  font-family: Arial;
  font-size: 20px;
  position: relative;
  top: -840px;
}
/* Loader css */