.main_login_signup {
    padding: 100px 0px;
}
.row_login_signup {
    display: flex;
    justify-content: center;
}
.main_lognin input {
    width: 100%;
    padding: 15px 10px;
    margin: 15px 0px;
    outline: none;
    background: #F4F4F4;
    border: 1px solid #8080808a;
}

.login_remember input {
    width: unset;
    cursor: pointer;
}

.login_remember {
    display: flex;
    align-items: baseline;
}

.main_lognin a {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
}

.logiin_radio {
    display: flex;
    align-items: baseline;
    width: 50%;
    gap: 10px;
    text-transform: capitalize;
    font-weight: 400;
}

.logiin_radio p {
    margin: 0;
    color: gray;
}

.login_remember a {
    display: flex;
    justify-content: end;
    text-decoration: none;
    color: gray;
    text-transform: capitalize;
}

.main_lognin h1 {
    text-transform: capitalize;
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 30px;
    color: #431647;
}

.main_lognin {
    border: 1px solid #8080809c;
    padding: 50px 20px;
    background: #fff;
}

.main_lognin.set p {
    margin: 0;
    color: gray;
    text-transform: capitalize;
}

.rejister_forget a {
    color: gray;
    text-transform: capitalize;
    text-decoration: none;
}

.theme-btn {
    width: 100%;
    color: rgb(255, 255, 255);
    text-decoration: none;
    padding: 10px 16px;
    text-transform: capitalize;
    font-weight: 600;
    transition: 0.50s ease;
    border: 1px solid;
    font-family: 'Poppins', sans-serif;
    background: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 0%, rgba(226, 128, 198, 1) 100%);
    border-image: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 0%, rgba(226, 128, 198, 1) 100%);
    border-image-slice: 1;
    transition: all 0.5s;
}

.theme-btn:hover {
    text-decoration: none;
    color: #818286;
    box-shadow: 3px 8px 12px -6px black;
    transform: translateY(-5px) !important;
    transition: 0.50s ease;
    background: transparent;
}

.theme-group {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 10px 0px;
    justify-content: start;
}

button.theme-btn {
    margin-top: 15px;
}

.login-bg{
    background-image: url(../../images/shop-bg.jpg);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }