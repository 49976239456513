@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    line-height: 1.7;
    overflow-x: hidden;
  }

:root {
    --color-secondary: #818286;
    --color-primary: #e280c6;
    --color-primary2: #8c72d7;
    --color-white: #fff;
    --color-black: #020001;
    --background-gradient: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 40%, rgba(226, 128, 198, 1) 55%);
    --background-gradient2: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 20%, rgba(226, 128, 198, 1) 100%);
}

img {
    object-fit: cover;
}

.main-container {
    display: flex;
}

.title {
    font-size: 3rem;
    display: grid;
    place-items: center;
}

.sidebar {
    background: var(--color-black);
    color: white;
    min-height: 80vh;
}

.top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 4px 0px 16px;
}

.top_section .logo img {
    width: 72px;
}

.bars {
    width: 30px;
}

.hide {
    display: none;
}

.search {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 30px;
    padding: 10px;
}

.search input {
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    background: rgb(238, 238, 238);
    color: white;
}

.routes {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

section.routes a.link {
    display: flex;
    color: white;
    padding: 10px 15px;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    gap: 16px;
}
section.routes a.link .link_text {
    font-weight: 700;
    font-size: 17px;
    text-transform: capitalize;
}

.menu_item .link_text {
    font-weight: 700;
    font-size: 17px;
    text-transform: capitalize;
}

.menu_item .icon svg {
    font-size: 22px;
}

.dash_menu_container .link {
    display: flex;
    color: #fff;
    gap: 0px !important;
    padding: 10px 35px 10px 10px;
    border-radius: 6px;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.dash_menu_container .link:hover {
    color: var(--color-black);
    background: #fff;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.dash_menu_container .active {
    color: var(--color-black) !important;
    background: #fff;
}

.dash_menu_container .link_text {
    white-space: nowrap;
    font-weight: 600;
}

.menu {
    display: flex;
    color: white;
    padding: 10px 15px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
}

.menu_item {
    display: flex;
    gap: 10px;
    align-items: center;
}

.dash_menu_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 45px;
    align-content: flex-start;
}

/************* End DASHBOARD CSS SIDEBAR  *************/
/************* Start DASHBOARD Every Page Card CSS  *************/
.dash-card {
    display: flex;
    justify-content: center;
}
.dash-sub-card{
    width: 80%;
    margin: 40px 0px;
}

.dash-card-header.card-header {
    background: #000;
    color: #fff;
    font-weight: 500;
}

.card_body {
    display: flex;
    gap: 30px;
    align-items: center;
    margin-top: 18px;
}
.card_body p{
    margin: 0px;
}
/************* End DASHBOARD Every Page Card CSS  *************/